import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import { calculateHoursDiff, handleWebserviceError, timeStringToFloat } from '../Helpers/Functions';
import { GET_CHART_OCCUPATION } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import { generateHoverGradient, generateOccupationWithGradients, generateOccupationWithSteps } from './ChartOccupation.helpers';

window.occupationInterval = null;
function ChartOccupation({ date, zone_id, t }) {

    const [chartDimensions, setChartDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [chartInfo, setChartInfo] = useState(null);
    const [chartData, setChartData] = useState({ chartDataKey: [], highcharts: undefined, zone_id });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    function defineGradients() {
        const chart = chartInfo
        const occupancies = chartData.highcharts.occupancy;
        if (!occupancies) return;
        occupancies.forEach(occupancy => {
            const gradOccupation = (occupancy / chartData.highcharts.capacity* 0.564) + 0.436;
            generateHoverGradient(`hover${occupancy}-open`, chart, { endColor: 'rgba(0, 255, 195, .1)', stopColor: '#666666' }, gradOccupation);
            generateHoverGradient(`hover${occupancy}-close`, chart, { endColor: 'rgba(200, 200, 200, .1)', stopColor: '#666666' }, gradOccupation);
        });
        window.addEventListener('resize', () => setChartDimensions({ width: window.innerWidth, height: window.innerHeight }));
    }

    useEffect(() => {
        if (chartData.zone_id !== undefined) {
            window.clearInterval(window.occupationInterval);
            callAPI(GET_CHART_OCCUPATION(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.occupationInterval = window.setInterval(() => {
                callAPI(GET_CHART_OCCUPATION(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts: {}, zone_id });
        }

    }, [chartData.zone_id, date]);

    useEffect(() => {
        if (chartData.zone_id !== zone_id) {
            setChartData({ highcharts: undefined, zone_id });
        }
    }, [zone_id]);

    useEffect(() => {
        if (data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CHART_OCCUPATION') {
            let decimalSchedule = data.highcharts.schedule.map(hour => Math.floor(timeStringToFloat(hour)));
            let hoursOpened = calculateHoursDiff(decimalSchedule[0], decimalSchedule[1]) + 1;
            decimalSchedule.push(decimalSchedule[1] < decimalSchedule[0]);
            setChartData({ ...chartData, chartDataKey: data.highcharts.occupancy ? data.highcharts.occupancy : data.highcharts.movements, decimalSchedule, hoursOpened, highcharts: data.highcharts });
        }
    }, [data]);

    useEffect(() => {
        if (chartInfo && chartInfo !== null && Object.keys(chartInfo).length > 0 && chartData.highcharts !== undefined) {
            defineGradients();
        }
    }, [chartInfo, chartData]);

    if (chartData.highcharts !== undefined) {
        var occupationChart = {
            plotOptions: {
                pie: {
                    borderWidth: 1,
                    shadow: false
                },
                series: {
                    point: {
                        events: {
                            mouseOver: function () {
                                this.options.oldColor = this.color;

                                if (this.custom === null) return;

                                if (chartData.highcharts.capacity && data.highcharts.occupancy && this.custom.occupancy === 0) return;
                                const relativeOccupancy = this.custom.occupancy / this.custom.capacity;
                                if (chartData.highcharts.capacity && data.highcharts.occupancy && relativeOccupancy < 1) {
                                    this.graphic.attr("fill", this.custom.isOpened ? `url(#hover${this.custom.occupancy}-open)` : `url(#hover${this.custom.occupancy}-close)`);
                                } else {
                                    this.graphic.attr("fill", "#666666");
                                    this.dataLabel !== undefined && this.dataLabel.css({ color: 'white' });
                                }
                            },
                            mouseOut: function () {
                                this.graphic.attr("fill", this.options.oldColor);
                                this.dataLabel !== undefined && this.dataLabel.css({ color: 'black' });
                            }
                        }
                    },
                    states: {
                        hover: {
                            halo: false
                        },
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    if (this.point.custom === null) return false;
                    let htmlTooltip = `<table>`;
                    htmlTooltip += `<tr><td style="padding-bottom: 8px">${this.point.custom.date} h</td></tr>`;

                    const excludedKeys = ['capacity', 'date', 'isOpened', 'step'];
                    for (let k in this.point.custom) {
                        if (!excludedKeys.includes(k) && this.point.custom[k] !== null && k === 'occupancy') {
                            const occupancyPercentage = this.point.custom[k] / this.point.custom.capacity * 100;
                            const occupancyDisplayPerc = (occupancyPercentage === Infinity) ? '+100' : (this.point.custom[k] === 0) ? '0' : occupancyPercentage.toFixed(0);
                            htmlTooltip += `<tr>
                                <td>${t('common.' + k + '_quant')}: <b>${this.point.custom[k]}</b> (${occupancyDisplayPerc}%)</td>
                            </tr>`;
                        } else if (!excludedKeys.includes(k) && this.point.custom[k] !== null) {
                            htmlTooltip += `<tr>
                                <td>${t('common.' + k + '_quant')}: <b>${this.point.custom[k]}</b></td>
                            </tr>`;
                        }
                    }
                    htmlTooltip += `</table>`;
                    return htmlTooltip;
                },
                useHTML: true,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: '#ccc',
                borderRadius: 5,
                shadow: true
            },
            title: false,
            series: [
                {
                    type: 'pie',
                    data: [
                        {
                            color: '#D8D8D8',
                            name: t('common.close'),
                            y: (24 - chartData.hoursOpened) * 7
                        },
                        {
                            color: '#00FFC3',
                            name: t('common.open'),
                            y: chartData.hoursOpened * 7
                        }
                    ],
                    dataLabels: {
                        enabled: false
                    },
                    startAngle: (Math.floor(chartData.decimalSchedule[1]) - 24) * 15 + 15,
                    size: '40%',
                    zIndex: 2,
                    enableMouseTracking: false
                },
                {
                    type: 'pie',
                    data: (chartData.highcharts.capacity && data.highcharts.occupancy) ? generateOccupationWithSteps({ ...chartData.highcharts, ...chartData }) : generateOccupationWithGradients({ ...chartData.highcharts, ...chartData }),
                    showInLegend: false,
                    dataLabels: {
                        color: '#42484A',
                        distance: -20,
                        style: {
                            fontFamily: 'Avenir, sans-serif',
                            fontSize: '16px',
                            textOutline: 0
                        },
                    }
                }
            ]
        };
    }

    if (chartData.highcharts !== undefined && occupationChart !== undefined && chartData.chartDataKey.length > 0) {
        return (
            <div>
                <div className='chart-container'>
                    <h6 className='chart-title pt-3 ml-3'>{t('common.' + (chartData.highcharts.occupancy ? "occupancy" : "movements") + '_title')}</h6>
                    <HighchartsReact
                        key={`occupation-${chartData.zone_id}-${chartDimensions.width}-${chartDimensions.height}`}
                        callback={(chartInfo) => setChartInfo(chartInfo)}
                        style={{ height: 'calc(100% - 42px)' }}
                        highcharts={Highcharts}
                        options={occupationChart}
                    />
                    <div className="occupation-leyend-group rotated-leyend">
                        <div className="occupation-leyend-item">
                            <p>{t('common.open')}</p>
                            <div className="leyend-point" style={{ background: 'rgba(0, 255, 195, 1)' }}></div>
                        </div>
                        <div className="occupation-leyend-item">
                            <p>{t('common.close')}</p>
                            <div className="leyend-point" style={{ background: 'rgba(200, 200, 200, 1)' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return false;
    }
}

export default withTranslation()(ChartOccupation);
