import React, { useEffect, useState } from "react";
import { handleWebserviceError } from "../Helpers/Functions";
import { Row, Button, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import {
  CHANGE_ZONE_SETTINGS,
  UNBLOCK_THERMOSTAT,
  PATCH_ALERTS_SETTINGS,
  GET_ALERTS_SETTINGS,
} from "../hooks/useAPI/actions";
import useAPI from "../hooks/useAPI";
import { ModuleTypes } from "../Helpers/ModuleTypes";
import ApplyZoneSettingsModal from "./ApplyZoneSettingsModal";

function SaveSettings({
  thermostatStatus,
  originalSettings,
  sameBuildingZones,
  savingSettings,
  setThermostatStatus,
  setZoneSettingsData,
  t,
  zoneId,
  zoneSettings,
  channels,
}) {
  const [allZonesId, setAllZonesId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState([]);
  const { callAPI, apiResult } = useAPI();
  const { data, type } = apiResult;

  function toggleZoneSelected(selectedZoneId) {
    let zones = sameBuildingZones;
    let zone = zones.find((zone) => zone.id === selectedZoneId);
    zone.isSelected = !zone.isSelected;
    setZoneSettingsData((prevState) => {
      return {
        ...prevState,
        sameBuildingZones: zones,
      };
    });
  }

  function askCancelChanges() {
    var cancelState = {
      currentSelectedDaySettings: this.getFirstSelectedDaySettings(),
      enableSaveSettings: false,
    };

    this.calculateProgressBars();

    setZoneSettingsData((prevState) => {
      return {
        ...prevState,
        ...cancelState,
      };
    });
  }

  function saveZoneSettings() {
    const selectedZones = sameBuildingZones.filter((z) => z.isSelected);

    if (selectedZones.length > 1 && !showModal) return setShowModal(true);

    sameBuildingZones.forEach((zone) => {
      let performCall = false;
      let loadingState = {
        enableSaveSettings: false,
        savingSettings: true,
      };
      if (zone.isSelected) {
        performCall = true;
        callAPI(
          CHANGE_ZONE_SETTINGS(zone.settings, zone.id),
          originalSettings(zone.id !== zoneId ? selectedSettings : undefined)
        );
        let thermostatModule = zone.modules.find(
          (module) => module.module_type === ModuleTypes.Clima
        );
        if (thermostatModule !== undefined && thermostatStatus.changed) {
          callAPI(UNBLOCK_THERMOSTAT(thermostatModule.id), {
            "Lock status": thermostatStatus.value ? "unblock" : "block",
          });
        }
        channels.forEach((type) => {
          let temp = {};
          type.channels.forEach((channel) => {
            temp[channel.label] = channel.checked;
          });
          allZonesId.forEach((zoneAlert) => {
            if (
              zone.id === zoneAlert.zoneId &&
              zoneAlert.typeId === type.type
            ) {
              callAPI(PATCH_ALERTS_SETTINGS(zoneAlert.id), temp);
            }
          });
        });
      }

      if (performCall) {
        setZoneSettingsData((prevState) => {
          return {
            ...prevState,
            ...loadingState,
          };
        });
      }
    });
  }

  useEffect(() => {
    if (sameBuildingZones !== undefined && sameBuildingZones.length > 0) {
      sameBuildingZones.forEach((zone) => {
        callAPI(GET_ALERTS_SETTINGS(zone.id));
      });
    }
  }, [sameBuildingZones.zone]);

  useEffect(() => {
    if (data != null && data.status_code !== undefined) {
      handleWebserviceError(data);
    } else if (
      data != null &&
      data.status_code === undefined &&
      type === "CHANGE_ZONE_SETTINGS"
    ) {
      let result = {
        settings: data !== false ? data : originalSettings(),
        savingSettings: false,
        enableSaveSettings: data !== false ? false : true,
      };

      let zones = sameBuildingZones.map((zone) => ({
        ...zone,
        isSelected: false,
      }));
      let zone = zones.find((zone) => zone.id === zoneId);
      zone.isSelected = true;

      setZoneSettingsData((prevState) => {
        return {
          ...prevState,
          ...result,
          sameBuildingZones: zones,
        };
      });
    } else if (
      data != null &&
      data.status_code === undefined &&
      type === "UNBLOCK_THERMOSTAT"
    ) {
      setThermostatStatus({ ...thermostatStatus, changed: false });
    } else if (data != null && type === "GET_ALERTS_SETTINGS") {
      let temp_allZonesId = [...allZonesId];
      data.forEach((type) => {
        temp_allZonesId.push({
          id: type.id,
          zoneId: type.zone,
          typeId: type.type,
        });
      });
      setAllZonesId(temp_allZonesId);
    }
  }, [data]);

  return (
    <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap pt-3 pb-2 mb-3">
      {sameBuildingZones.length > 1 && (
        <div className="card w-100">
          <Row>
            <div className="col-md-5 d-inline-flex align-content-center">
              <span className="d-inline-block pt-3 pl-3">
                {t("climate.select_other_zones")}
              </span>
            </div>
            <div className="col-md-7">
              <div className="ml-3 zone-list my-3">
                {sameBuildingZones.map(function (zone) {
                  if (zone.id !== zoneId) {
                    return (
                      <button
                        className={
                          zone.isSelected
                            ? "btn btn-success mr-2 mb-2"
                            : "btn btn-light mr-2 mb-2"
                        }
                        onClick={() => toggleZoneSelected(zone.id)}
                        key={zone.id}
                      >
                        {zone.name}
                      </button>
                    );
                  } else {
                    return false;
                  }
                })}
              </div>
            </div>
          </Row>
        </div>
      )}
      {sameBuildingZones.length <= 1 && <div className="w-100"></div>}
      <div className="mt-3 mt-lg-0 ml-lg-3">
        <button
          data-testid="buttonSaveDay"
          className="btn btn-orpheus-fill btn-large float-right"
          onClick={saveZoneSettings}
        >
          {savingSettings && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {savingSettings ? "" : t("common.save")}
        </button>
        <Button
          data-testid="buttonCancelDay"
          style={{ marginLeft: "1em", display: "none" }}
          variant="link"
          onClick={() => askCancelChanges.bind(this)}
        >
          {t("common.cancel")}
        </Button>
      </div>
      <ApplyZoneSettingsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        saveZoneSettings={saveZoneSettings}
        selectedSettings={selectedSettings}
        selectedZones={sameBuildingZones}
        setSelectedSettings={setSelectedSettings}
      />
    </div>
  );
}

export default withTranslation()(SaveSettings);
