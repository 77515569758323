import React, { useEffect, useState } from 'react';
import Indicator from '../Components/Indicator';
import { handleWebserviceError } from '../Helpers/Functions';
import { GET_CO2_KPIS } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import LinkWrapper from '../Helpers/LinkWrapper';
import { Link } from 'react-router-dom';

function ZoneCard({ mode, module, zone_alerts, setCurrentKpi }) {
    const [cardData, setCardData] = useState({ name: module.name, module, kpis: [], status: '' });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    useEffect(() => {
        setCardData({ name: module.name, module, kpis: [], status: '' })
    }, [JSON.stringify(module)])

    useEffect(() => {
        callAPI(GET_CO2_KPIS(module.id));
    }, [module.id])

    useEffect(() => {
        if (data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CO2_KPIS') {
            setCardData({ ...cardData, id: module.zone_id, name: module.name, module, kpis: data, status: (data.status !== undefined) ? data.status.value : '' });
            setCurrentKpi(data)
        }
    }, [data]);

    let cardClass = (cardData.kpis.air_quality !== undefined) ? cardData.kpis.air_quality.value >= 0.7 ? 'good-quality' : cardData.kpis.air_quality.value >= 0.5 ? 'medium-quality' : 'bad-quality' : '';
    let modeClass = (mode === 'chart') ? 'mode-chart' : '';
    if (cardData.kpis.error === undefined && Object.entries(cardData.kpis).length > 0) {
        return (
            <LinkWrapper className="building-zone-link" link={cardData.kpis['link']}>
                <div className={`building-zone ${cardClass} ${modeClass}`}>
                    {
                        (zone_alerts > 0) ? (
                            <Link
                                to={{
                                    pathname: "/notifications",
                                    state: {idList : [module.zone_id], toFilter: true}
                                }}>
                                <div className='alerts alerts-cards'>{zone_alerts}!</div>
                            </Link>
                        ) : false
                    }
                    {
                        (mode === 'card') ? (<h2 className="building-zone-title">{cardData.name}</h2>) : false
                    }
                    {
                        (Object.entries(cardData.kpis).length > 0) ? (
                            <div className="building-zone-indicators">
                                <Indicator
                                    title={'air_quality'}
                                    unit={cardData.kpis.air_quality.unit}
                                    value={cardData.kpis.air_quality.value}
                                />
                                <Indicator
                                    air_quality={cardData.kpis.air_quality.value}
                                    status={cardData.kpis.status.value}
                                    title='status'
                                    unit={(cardData.kpis.status.value === 'closings') ? cardData.kpis.time_to_next_ventilation.unit : (cardData.kpis.status.value === 'ventilations') ? cardData.kpis.required_ventilation_time.unit : null}
                                    value={(cardData.kpis.status.value === 'closings') ? cardData.kpis.time_to_next_ventilation.value : (cardData.kpis.status.value === 'ventilations') ? cardData.kpis.required_ventilation_time.value : null}
                                />
                            </div>
                        ) : false
                    }
                </div>
            </LinkWrapper>
        )
    } else {
        return (
            <div className="building-zone no-quality">
                {
                    (zone_alerts > 0) ? (
                        <Link
                            to={{
                                pathname: "/notifications",
                                state: {idList : [module.zone_id], toFilter: true}
                            }}>
                            <div className='alerts alerts-cards'>{zone_alerts}!</div>
                        </Link>
                    ) : false
                }
                {
                    (mode === 'card') ? (<h2 className="building-zone-title-disabled">{cardData.name}</h2>) : false
                }
            </div>
        )
    }
}

export default ZoneCard;
