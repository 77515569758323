import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

function ApplyZoneSettingsModal({ show, onHide, saveZoneSettings, selectedSettings, selectedZones, setSelectedSettings, t }) {
  const settingsOptions = [
    { id: "temperature", label: t("common.temperature") },
    {
      id: "co2",
      label: (
        <>
          Max CO<sub>2</sub>
        </>
      ),
    },
    { id: "capacity", label: t("common.seating_capacity") },
	  { id: "schedule", label: t("common.weekly_schedule") },
    { id: "holidays", label: t("common.public_holidays") },
    { id: "lights", label: t("common.co2_light_warning") },
  ];

  const allSelected = selectedSettings.length === settingsOptions.length;

  const toggleAll = () => {
    if (allSelected) {
      setSelectedSettings([]);
    } else {
      setSelectedSettings(settingsOptions.map((option) => option.id));
    }
  };

  const toggleSetting = (settingId) => {
    if (selectedSettings.includes(settingId)) {
      setSelectedSettings(selectedSettings.filter((id) => id !== settingId));
    } else {
      setSelectedSettings([...selectedSettings, settingId]);
    }
  };

  const otherSelectedZones = selectedZones ? selectedZones.filter(z => z.isSelected).length - 1 : 0;

  return (
    <Modal show={show} onHide={onHide} centered className="apply-zone-settings-modal">
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title>{t("settings_modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-3">
			    {otherSelectedZones === 1 ? t("settings_modal.description_single") : t("settings_modal.description", { zones: otherSelectedZones })}
        </p>
        <a
          className="p-0 mb-2 d-flex text-sm justify-content-start font-italic"
		  role="button"
          onClick={toggleAll}
        >
          {t("common.select_all")}
        </a>
        <div
          className="settings-list"
        >
          {settingsOptions.map((option) => (
            <div className="px-1 d-flex flex-row align-items-center mb-2">
              <label className="switch mb-0 mr-2" title="">
                <input
                  key={option.id}
                  name="switch"
                  type="checkbox"
                  value="1"
                  checked={selectedSettings.includes(option.id)}
                  onChange={() => toggleSetting(option.id)}
                />
                <span className="slider round" />
              </label>
			        <span>{option.label}</span>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-orpheus-fill float-right px-3 py-2 border-0 font-weight-bold"
          onClick={() => {
            saveZoneSettings();
            onHide();
          }}
        >
          <span>{t("common.apply_changes")}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withTranslation()(ApplyZoneSettingsModal);
