import React from 'react';
import { withTranslation } from 'react-i18next';

function TemperatureController({ co2_threshold, enabled_co2, seatingCapacity, setZoneSettingsData, t, target }) {
    function changeCo2Value(e) {
        if (!isNaN(e.target.value)) {
            setZoneSettingsData((prevState) => {
                return {
                    ...prevState,
                    co2_threshold: e.target.value
                }
            })
        }
    }
    function changeTemperature(e) {
        if (!isNaN(e.target.value)) {
            setZoneSettingsData((prevState) => {
                return {
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        target: e.target.value
                    },
                    target: e.target.value,
                    enableSaveSettings: true
                };
            });
        } else {
            return false;
        }
    };
    function changeSeatingCapacity(e) {
        if (!isNaN(e.target.value)) {
            setZoneSettingsData((prevState) => {
                return {
                    ...prevState,
                    people_capacity: parseInt(e.target.value) ?? 0
                };
            });
        } else {
            return false;
        }
    };

    return(
        <div className='card p-3 mb-sm-3 mb-md-0'>
            <h5 className='chart-title'>{t('climate.select_desired_values')}</h5>
            <div className='d-inline-flex flex-wrap'>
                <div className="d-flex flex-column mr-4">
                    <label htmlFor='target' className="m-1">{t('common.temperature')} ºC</label>
                    <input
                        type='number'
                        name='target'
                        className='number-selector m-1'
                        id='target'
                        min='17'
                        max='30'
                        placeholder='21.0'
                        value={target ? target : '' }
                        onChange={changeTemperature}
                    />
                </div>

                {
                    enabled_co2 && (
                        <div className="d-flex flex-column mr-4">
                            <label htmlFor='co2' className="m-1">Max CO<sub>2</sub> ppm</label>
                            <input
                                type='number'
                                name='co2'
                                className='number-selector m-1'
                                id='co2'
                                min='700'
                                max='1500'
                                placeholder='1000'
                                value={co2_threshold ? co2_threshold : '' }
                                onChange={changeCo2Value}
                            />
                        </div>
                    )
                }

                <div className="d-flex flex-column mr-4">
                    <label htmlFor='target' className="m-1">{t('common.seating_capacity')}</label>
                    <input
                        type='number'
                        name='target'
                        className='number-selector m-1'
                        id='target'
                        min='0'
                        max='9999'
                        placeholder='23'
                        value={seatingCapacity ? seatingCapacity : '' }
                        onChange={changeSeatingCapacity}
                    />
                </div>
            </div>
        </div>
    )
};

export default withTranslation()(TemperatureController);
