import React, { useContext, useEffect, useState } from 'react';
import { handleWebserviceError } from '../../Helpers/Functions';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import '../../Styles/settings.css'
import ca from 'date-fns/locale/ca';
import es from 'date-fns/locale/es';
import Loading from '../../Components/Loading';
import { withTranslation } from 'react-i18next';
import { INITIAL_STATE, PREPARED_SETTINGS } from './data';
import { GET_ZONE_SETTINGS, UNBLOCK_THERMOSTAT_STATUS, GET_ALERTS_SETTINGS, UPDATE_SUBSCRIPTION_DATE } from '../../hooks/useAPI/actions';
import { useHistory } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import SettingsTimetable from '../../Components/SettingsTimetable';
import TemperatureController from '../../Components/TemperatureController';
import HolidaysCalendar from '../../Components/HolidaysCalendar';
import PreferencesTypes from '../../Components/PreferencesTypes';
import PreferencesPicker from '../../Components/PreferencesPicker';
import Navigation from '../../Components/Navigation';
import SaveSettings from '../../Components/SaveSettings';
import SettingsSidebar from '../../Components/SettingsSidebar';
import UtilsContext, { ALERTS_TOGGLE, ADMIN_USERS_ID } from '../../Context/utilsContext';
import { ModuleTypes } from '../../Helpers/ModuleTypes';
import moment from 'moment';
import EdiText from 'react-editext'
import styled from 'styled-components'

registerLocale('ca', ca);
registerLocale('es', es);
var _ = require('lodash');

const StyledEdiText = styled(EdiText)`
    button {
        border-radius: 5px;
        padding: 0;
        margin: 0;
    }
    input, textarea {
        padding: 10px 15px;
        border-radius: 5px;
        height: 1.45rem;
    }
`

function ZoneSettings({ t }) {
    const { customers, updateSubscriptionExpiryDate } = useContext(UtilsContext);
    const [zoneSettingsData, setZoneSettingsData] = useState(INITIAL_STATE(t));
    const [thermostatStatus, setThermostatStatus] = useState({ changed: false, value: undefined });
    const [channels, setChannels] = useState([]);
    const history = useHistory();
    const [pickerStatus, setPickerStatus] = useState(1);
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    let lightingModule = null;
    let thermostatModule = null;
    let buildingHasCO2 = false;
    if(zoneSettingsData.zone !== undefined) {
        zoneSettingsData.zone.modules.forEach(module => (module.module_type === ModuleTypes.CO2) ? buildingHasCO2 = true : false);
        lightingModule = zoneSettingsData.zone.modules.find(module => (module.module_type === ModuleTypes.Lighting));
        thermostatModule = zoneSettingsData.zone.modules.find(module => (module.module_type === ModuleTypes.Clima));
    }

    function getSettings(selectedConfigurations) {
        const configurationsInfo = {
            "temperature": ["target"],
            "co2":  ["co2_threshold"],
            "capacity": ["people_capacity"],
            "schedule": [
                "monday_enabled", "monday_start", "monday_end", "tuesday_enabled", "tuesday_start", "tuesday_end", "wednesday_enabled",
                "wednesday_start", "wednesday_end", "thursday_enabled", "thursday_start", "thursday_end", "friday_enabled", "friday_start",
                "friday_end", "saturday_enabled", "saturday_start", "saturday_end", "sunday_enabled", "sunday_start", "sunday_end"
            ],
            "holidays": ["excluded_dates"],
            "lights": ["co2_light_warning"]
        };
        const choosenSettings = [];

        if(selectedConfigurations && selectedConfigurations.length > 0) {
            selectedConfigurations.forEach(selectedConfig => {
                choosenSettings.push(...configurationsInfo[selectedConfig])
            });
        };

        return PREPARED_SETTINGS(zoneSettingsData, choosenSettings);
    }

    useEffect(() => {
        if (ALERTS_TOGGLE) {
            callAPI(GET_ALERTS_SETTINGS(zoneSettingsData.zoneId));
        }
    }, [zoneSettingsData.zoneId])

    useEffect(() => {
        if(zoneSettingsData.zone !== undefined) {
            callAPI(GET_ZONE_SETTINGS(zoneSettingsData.zone.settings, zoneSettingsData.zoneId));
        }
    }, [zoneSettingsData.zone]);

    useEffect(() => {
        if(thermostatModule != null) {
            callAPI(UNBLOCK_THERMOSTAT_STATUS(thermostatModule.id));
        }
    }, [thermostatModule]);

    useEffect(() => {
        if(Object.keys(customers).length > 0) {
            let zones = [];
            let { buildings } = customers;
            let selectedZone = null;

            buildings.forEach(building => building.zones.forEach(buildingZone => (buildingZone.id === parseInt(zoneSettingsData.zoneId)) ? selectedZone = { ...buildingZone, building } : null));

            buildings.map(building => building.zones.map(zone => zones.push(zone)));

            let buildingId = buildings.find(building => (
                building.zones.map(zone => zone.id).indexOf(zoneSettingsData.zoneId) > -1
            )).id;

            let sameBuildingZones = buildings.find(building=> building.id === buildingId).zones
            sameBuildingZones.forEach(zone => {
                zone.isSelected = false;
            });

            let zone = zones.find(zone => zone.id === zoneSettingsData.zoneId)
            zone.isSelected = true;

            setZoneSettingsData({
                ...zoneSettingsData,
                building: buildings.find(b => b.id === buildingId),
                zone: selectedZone,
                sameBuildingZones,
            });
        }
    }, [customers]);

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_ZONE_SETTINGS') {
            let days = {
                monday: {
                    enabled: data.monday_enabled,
                    start: data.monday_start,
                    end: data.monday_end,
                },
                tuesday: {
                    enabled: data.tuesday_enabled,
                    start: data.tuesday_start,
                    end: data.tuesday_end,
                },
                wednesday: {
                    enabled: data.wednesday_enabled,
                    start: data.wednesday_start,
                    end: data.wednesday_end,
                },
                thursday: {
                    enabled: data.thursday_enabled,
                    start: data.thursday_start,
                    end: data.thursday_end,
                },
                friday: {
                    enabled: data.friday_enabled,
                    start: data.friday_start,
                    end: data.friday_end,
                },
                saturday: {
                    enabled: data.saturday_enabled,
                    start: data.saturday_start,
                    end: data.saturday_end,
                },
                sunday: {
                    enabled: data.sunday_enabled,
                    start: data.sunday_start,
                    end: data.sunday_end,
                }
            };

            setZoneSettingsData({
                ...zoneSettingsData,
                settings: data,
                originalSettings: _.cloneDeep(data),
                target: data.target,
                loading: false,
                currentSelectedDays: [],
                currentSelectedDaySettings: {
                    enabled: false,
                    start: null,
                    end: null
                },
                progressPercent1: 100,
                progressPercent2: 0,
                progressPercent3: 0,
                days,
                co2_threshold: data.co2_threshold,
                co2_light_warning: data.co2_light_warning
            });
        } else if (data != null && data.error === undefined && type === 'UNBLOCK_THERMOSTAT_STATUS') {
            setThermostatStatus((data['Lock status'] === 'unblocked') ? { changed: false, value: true } : { changed: false, value: false });
        } else if (data != null && type === 'GET_ALERTS_SETTINGS') {
            let temp = []
            if (data !== null) {
                data.forEach(element => {
                    temp.push({type: element.type, id: element.id, category: element.category, channels: [
                        {label: "active", checked: element.active},
                        {label: "webpush", checked: element.webpush},
                        {label: "whatsapp", checked: element.whatsapp},
                        {label: "email", checked: element.email}]})
                });
            }
            setChannels(temp)
        }
    }, [data]);

    function changeSubscriptionDate(new_date) {
        updateSubscriptionExpiryDate(zoneSettingsData.building.id, new_date);
        callAPI(UPDATE_SUBSCRIPTION_DATE, {'subscription_expiry_date_date': new_date, 'building_id': zoneSettingsData.building.id});
    }

    let sidebarSettings = [];
    if(lightingModule != null && zoneSettingsData.co2_light_warning != null) {
        sidebarSettings.push({ prop: 'co2_light_warning', value: (zoneSettingsData.co2_light_warning) ? zoneSettingsData.co2_light_warning : false });
    }
    if(thermostatModule != null) {
        sidebarSettings.push({ prop: 'thermostat_status', value: thermostatStatus.value });
    }

    let content;

    if (zoneSettingsData.loading) {
        content = (<Loading></Loading>)
    } else if (moment(zoneSettingsData.building.subscription_expiry_date, "YYYY-MM-DD") < moment().startOf('day')) {
        history.push('/building/' + zoneSettingsData.building.id)
    } else {
        content = (
            <main role='main' className='col-12 settings-page'>
                <div className='info-sidebar'>
                    <div className='expiration-date'>
                        {t('common.expiration_date')}:&nbsp;
                        {
                            (customers.user in ADMIN_USERS_ID) ? (
                                <StyledEdiText
                                    type='date'
                                    value={moment(zoneSettingsData.building.subscription_expiry_date, "YYYY-MM-DD").format('L')}
                                    onSave={v => changeSubscriptionDate(v)}
                                />
                            ) : (moment(zoneSettingsData.building.subscription_expiry_date, "YYYY-MM-DD").format('L'))
                        }
                    </div>
                </div>
                <Navigation
                    action={t('common.panel')}
                    actionIcon={<div className='ico ico--dashboard'/>}
                    actionUrl={`/dashboard/${zoneSettingsData.zone.id}`}
                    items={[zoneSettingsData.zone.building.name, zoneSettingsData.zone.name]}
                />
                <div className='info-sidebar sidebar-device-settings'>
                    <SettingsSidebar
                        items={sidebarSettings}
                        unblockThermostat={() => setThermostatStatus({ changed: true, value: !thermostatStatus.value })}
                        setZoneSettingsData={setZoneSettingsData}
                    />
                </div>
                {
                    (ALERTS_TOGGLE) ? (
                        <PreferencesPicker
                            pickerStatus={pickerStatus}
                            setPickerStatus={setPickerStatus}
                        />
                    ) : false
                }
				<TemperatureController
					co2_threshold={zoneSettingsData.co2_threshold}
					enabled_co2={buildingHasCO2}
					seatingCapacity={zoneSettingsData.people_capacity ?? zoneSettingsData.settings.people_capacity}
					setZoneSettingsData={setZoneSettingsData}
					target={zoneSettingsData.target}
				/>
                <div className='scheduler'>
                    {
                        (pickerStatus === 1) ? (
                            <div className='scheduler-multiple'>
								<SettingsTimetable
									days={zoneSettingsData.days}
									setZoneSettingsData={setZoneSettingsData}
								/>
								<HolidaysCalendar
									excludedDates={zoneSettingsData.settings.excluded_dates}
									setZoneSettingsData={setZoneSettingsData}
								/>
							</div>
                        ) : (pickerStatus === 2) ? (
                            <div className='scheduler-multiple scheduler-multiple-alerts'>
                                <PreferencesTypes
                                    channels={channels}
                                    setChannels={setChannels}
                                />
                            </div>
                        ) : false
                    }
                </div>
                <SaveSettings
                    thermostatStatus={thermostatStatus}
                    originalSettings={getSettings}
                    sameBuildingZones={zoneSettingsData.sameBuildingZones}
                    savingSettings={zoneSettingsData.savingSettings}
                    setZoneSettingsData={setZoneSettingsData}
                    setThermostatStatus={setThermostatStatus}
                    zoneId={zoneSettingsData.zoneId}
                    zoneSettings={zoneSettingsData.zone.settings}
                    channels={channels}
                />
            </main>
        );
    }
    return content;
}

export default withTranslation()(ZoneSettings);
