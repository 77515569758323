import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { classList, Globals } from '../Helpers/Functions';
import Loading from '../Components/Loading';
import Arrow from '../Img/icons/left_arrow.svg';
import UtilsContext from '../Context/utilsContext';

function Buildings({ history, i18n, t }) {
    const [customerData, setCustomerData] = useState(undefined);
    const { customers } = useContext(UtilsContext);

    function changeLanguage (event) {
        i18n.changeLanguage(event.target.value);
        window.localStorage.setItem('orpheus_language', event.target.value);
    }

    useEffect(() => {
        setCustomerData(customers);
    }, [customers]);

    var content;
    if (customerData === undefined || customerData.length === 0) {
        content = (<Loading></Loading>)
    } else if (customerData.buildings.length === 0) {
        content = (
            <main role='main' className='col-12'>
                <div
                    className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
                    <h2>Nothing to see here yet</h2>{' '}
                    <Button onClick={() => history.go(-1)}>
                        {t('common.back')}
                    </Button>
                </div>
            </main>
        );
    } else {
        content = (
            <main role='main' className='col-12 main-dashboard'>
                <div className='language-selector'>
                    <button value='ca' className={classList({
                        'btn btn-orpheus-fill-gray btn-small mr-2': true,
                        'is-active': i18n.language === 'ca'
                    })} onClick={changeLanguage}>CA</button>
                    <button value='es' className={classList({
                        'btn btn-orpheus-fill-gray btn-small mr-2': true,
                        'is-active': i18n.language === 'es'
                    })} onClick={changeLanguage}>ES</button>
                    <button value='en' className={classList({
                        'btn btn-orpheus-fill-gray btn-small mr-2': true,
                        'is-active': i18n.language === 'en'
                    })} onClick={changeLanguage}>EN</button>
                </div>
                <div className='welcome'>
                    <h1>{t('welcome.title')} {Globals.user.first_name}.</h1>
                    <h2>{t('welcome.subtitle')}</h2>
                    <img src={Arrow} alt='arrow left'/>
                </div>
            </main>
        );
    }

    return content;
};

export default withTranslation()(Buildings);
