import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { isPast, addDays } from 'date-fns';

function HolidaysCalendar({ excludedDates, i18n, setZoneSettingsData, t }) {
    function handleVacationDaysChange(date) {
        var changedexcludedDates = excludedDates;

        var selectedDate = moment(date).format('YYYY-MM-DD');
        var index = excludedDates.indexOf(selectedDate);
        if (index > -1) {
            changedexcludedDates.splice(index, 1);
        } else {
            changedexcludedDates.push(moment(date).format('YYYY-MM-DD'));
        }
        setZoneSettingsData((prevState) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    excluded_dates: changedexcludedDates
                },
                enableSaveSettings: true
            };
        });
    }

    function isTodayOrAfter(date) {
        return !isPast(addDays(date, 1))
    }

    var calendarDates = excludedDates.map(v => {
        return new moment(v, 'YYYY-MM-DD').toDate();
    });

    return(
        <div className='card p-3 mb-sm-3 mb-md-0'>
            <label htmlFor='freeDays'>
                <h5 className='chart-title'>{t('climate.select_holidays')}</h5>
            </label>
            <DatePicker
                inline
                locale={i18n.language}
                highlightDates={calendarDates}
                filterDate={isTodayOrAfter}
                onChange={date => handleVacationDaysChange(date)}
                monthsShown={1}
                disabledKeyboardNavigation
            />
            <input
                type='hidden'
                className='form-control date'
                id='freeDays'
                name='freeDays'
                placeholder='Días festivos'
            />
        </div>
    )
};

export default withTranslation()(HolidaysCalendar);
