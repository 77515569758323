import React from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

export class Globals {
    static token = 'ce90d45ebb2faba0753ab537d666a626b26e37b1';
    static user = undefined;
}

export function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <FormLabel>{label}</FormLabel>
            <FormControl {...props} />
            {help && <>{help}</>}
        </FormGroup>
    );
}

export function getParamsForUrl(params) {
    var strArr = [];
    for (var key in params) {
        if (params[key] !== undefined && params[key] !== null) {
            strArr.push(key + '=' + params[key].toString());
        }
    }
    return strArr.join('&');
}

export function handleWebserviceError(data, onPressCallback) {
    var errorMessage = data;
    if (typeof data !== 'string') {
        if ('status_code' in data && 'detail' in data) {
            errorMessage = data.detail;
        }
    }

    if (errorMessage === 'Network request failed') {
        // IRNA-41: Sin conexión -> Reintentar!
    } else if (errorMessage === 'Invalid token.') {
        // GOF-314: Sesión caducada -> volver al login
        console.error(errorMessage);
        window.location.href = '/';
    } else if (errorMessage !== undefined) {
        console.error(errorMessage);
    }
}

export default class Session {
    static async getUserData() {
        if (Globals.token === '') {
            return false;
        }
        return fetch(process.env.REACT_APP_ENDPOINT + '/api/users/me/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                Authorization: 'token ' + Globals.token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data === undefined)
                    throw new Error('Network request failed');
                if (!('status_code' in data)) {
                    Globals.user = data;
                    return true;
                } else {
                    //handleWebserviceError(data);
                    return false;
                }
            })
            .catch(reason => {
                console.log(reason);
                handleWebserviceError(reason);
                return false;
            });
    }
}

export function classList(classes) {
    return Object
        .entries(classes)
        .filter(entry => entry[1])
        .map(entry => entry[0])
        .join(' ');
}

export function pollingEffect(_promise, interval = 300000) {
    let polling
    const pollingData = async () => {
        await _promise()
        polling = setInterval(async () => {
            await _promise()
        }, interval)
    }

    pollingData()

    return () => {
        clearInterval(polling)
    }
}

export async function fetchWithTimeout(resource, options) {
    const { timeout = 240000, ...params } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...params,
        signal: controller.signal
    });

    clearTimeout(id);
    return response;
}

export function formatHours(value) {
    let hours = Math.trunc(value)
    let minutes = (value % 1) * 60
    const minutes_trunc = Math.trunc(minutes)
    let seconds = Math.round((minutes % 1) * 60)
    const formattedSeconds =
      seconds > 0 ? `${seconds < 10 ? `0${seconds}` : seconds}` : '00'
    const formattedMinutes =
      minutes_trunc > 0
        ? `${
            minutes_trunc < 10 ? `0${minutes_trunc}` : minutes_trunc
          }:${formattedSeconds}`
        : `00:${formattedSeconds}`

    let valueFormatted = ''
    if (value === 0) {
      valueFormatted = `${hours > 0 ? `${hours}:` : '00:'}${formattedMinutes}`
    } else {
      valueFormatted = `${
        hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : '00:'
      }${formattedMinutes}`
    }
    return valueFormatted
  }

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
}

export function returnTagColor(tag) {
    let color = '#7E8097';
    if(tag === 0) {
        color = '#FFB3B3';
    } else if(tag === 1) {
        color = '#FDE39F';
    } else if(tag === 2) {
        color = '#B3B5FE';
    } else if(tag === 3) {
        color = '#8CB9DB';
    }
    return color;
}

export function timeStringToFloat(time) {
    let hoursMinutes = time.split(/[.:]/);
    let hours = parseInt(hoursMinutes[0], 10);
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
}

export function calculateHoursDiff(hour1, hour2) {
    let firstHour = { hours: Math.floor(hour1), minutes: hour1 % Math.floor(hour1) * 60 };
    let secondHour = { hours: Math.floor(hour2), minutes: hour2 % Math.floor(hour2) * 60 };
    let firstDate = new Date();
    let secondDate = new Date();

    firstDate.setHours(firstHour.hours);
    firstDate.setMinutes(firstHour.minutes);

    if(hour2 < hour1) {
        secondDate.setDate(secondDate.getDate() + 1);
    }

    secondDate.setHours(secondHour.hours);
    secondDate.setMinutes(secondHour.minutes);
    let hoursDiff = (parseInt(secondDate.getTime()/1000) - parseInt(firstDate.getTime()/1000)) / 3600;
    return hoursDiff;
}
