import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { handleWebserviceError } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import { GET_CHART_TEMPERATURE, GET_CHART_HUMIDITY } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';

window.tempInterval = null;
function ChartTemperature({ date, zone_id, t }) {
    const [chartData, setChartData] = useState({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    useEffect(() => {
        window.clearInterval(window.tempInterval);
        if (chartData.zone_id !== undefined) {
            callAPI(GET_CHART_TEMPERATURE(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            callAPI(GET_CHART_HUMIDITY(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.tempInterval = window.setInterval(() => {
                callAPI(GET_CHART_TEMPERATURE(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
                callAPI(GET_CHART_HUMIDITY(chartData.zone_id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
        }
    }, [chartData.zone_id, date]);

    useEffect(() => {
        if (chartData.zone_id !== zone_id) {
            setChartData({ highcharts_temp: {}, highcharts_hum: {}, zone_id });
        }
    }, [zone_id]);

    useEffect(() => {
        if (data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined) {
            if (type === 'GET_CHART_TEMPERATURE') {
                setChartData({ ...chartData, highcharts_temp: data['highcharts'] });
            } else if (type === 'GET_CHART_HUMIDITY') {
                setChartData({
                    ...chartData,
                    highcharts_hum: {
                        ...data['highcharts'],
                        Humidity: data['highcharts'].Humidity.map(value => Math.round(value * 100))
                    }
                });
            }
        }
    }, [data]);

    if (chartData.zone_id) {
        // Determine if we should show external temperature and humidity in the series
        const hasExternalTemp = chartData.highcharts_temp.External_temperature &&
            chartData.highcharts_temp.External_temperature.length > 0;
        const hasHumidity = chartData.highcharts_hum.Humidity &&
            chartData.highcharts_hum.Humidity.length > 0;

        // Build series array dynamically based on available data
        const seriesArray = [
            {
                name: t('common.interior'),
                color: '#00FFCC',
                data: chartData.highcharts_temp.Temperature,
                type: 'areaspline',
                enableMouseTracking: true,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, 'rgb(179,255,241)'],
                        [1, 'rgba(216,255,247,0)']
                    ]
                },
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 3,
                        marker: {
                            radius: 4,
                        }
                    }
                },
                threshold: null,
                marker: {
                    enabled: false
                }
            }
        ];

        // Only add external temperature series if data exists
        if (hasExternalTemp) {
            seriesArray.push({
                name: t('common.exterior'),
                color: '#b3b3b3',
                data: chartData.highcharts_temp.External_temperature,
                type: 'areaspline',
                enableMouseTracking: true,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, 'rgb(217,217,217)'],
                        [1, 'rgba(245,245,245,0)']
                    ]
                },
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 3,
                        marker: {
                            radius: 4,
                        }
                    }
                },
                threshold: null,
                marker: {
                    enabled: false
                }
            });
        }

        // Only add humidity series if data exists
        if (hasHumidity) {
            seriesArray.push({
                name: t('climate.humidity'),
                color: '#66b2ff',
                data: chartData.highcharts_hum.Humidity,
                type: 'line',
                dashStyle: 'LongDash',
                yAxis: 1,
                lineWidth: 2,
                enableMouseTracking: true,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            fillColor: '#66b2ff',
                            radius: 4
                        }
                    }
                },
                states: {
                    hover: {
                        enabled: true,
                        lineWidth: 3,
                        halo: {
                            size: 5,
                            opacity: 0.2,
                            attributes: {
                                fill: '#66b2ff'
                            }
                        }
                    }
                },
                visible: false,
                dataLabels: {
                    enabled: false
                },
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, 'rgba(102, 178, 255, 0.3)'],
                        [1, 'rgba(102, 178, 255, 0)']
                    ]
                },
                events: {
                    show: function () {
                        this.chart.yAxis[1].update({
                            title: {
                                text: `${t('climate.humidity')} (%)`
                            }
                        });
                    },
                    hide: function () {
                        this.chart.yAxis[1].update({
                            title: {
                                text: null
                            }
                        });
                        this.chart.render();
                    }
                }
            });
        }

        var chartTemperatura = {
            chart: {
                type: 'area'
            },
            xAxis: {
                categories: chartData.highcharts_temp.date,
                lineColor: '#CCD6EB',
                labels: {
                    rotation: -45,
                    align: 'right'
                }
            },
            title: false,
            yAxis: [
                {
                    title: {
                        text: `${t('common.temperature')} (°C)`
                    },
                    minTickInterval: 1,
                    allowDecimals: false,
                },
                {
                    title: {
                        text: null,
                    },
                    opposite: true,
                    allowDecimals: false,
                }
            ],
            tooltip: {
                formatter: function () {
                    let unit = this.series.name === t('climate.humidity') ? '%' : '°C';

                    let tooltipHtml = `<table>`;
                    tooltipHtml += `<tr><td style="padding-bottom: 8px;">${this.x} h</td></tr>`;
                    tooltipHtml += `<tr><td>${this.series.name}: <b>${this.y} ${unit}</b></td></tr>`;
                    tooltipHtml += `</table>`;

                    return tooltipHtml;
                },
                useHTML: true,
                shared: false,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: '#ccc',
                borderRadius: 5,
                shadow: true
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: seriesArray
        };

        if (chartData.zone_id !== undefined &&
            chartData.highcharts_temp.Temperature !== undefined &&
            chartData.highcharts_temp.Temperature.length > 0
        ) {
            return (
                <div>
                    <div className='chart-container'>
                        <h6 className='chart-title pt-3 ml-3'>{t('climate.temperature_evolution')}</h6>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartTemperatura}
                        />
                    </div>
                </div>
            )
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export default withTranslation()(ChartTemperature);
